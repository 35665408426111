import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/3cba753e/src/layouts/about-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const ExperienceSection = makeShortcode("ExperienceSection");
const PortfolioSection = makeShortcode("PortfolioSection");
const Grid = makeShortcode("Grid");
const SkillSection = makeShortcode("SkillSection");
const LinkedInSkillSection = makeShortcode("LinkedInSkillSection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box sx={{
      textAlign: 'center'
    }} mdxType="Box">
      <h1>{`Experience`}</h1>
    </Box>
    <ExperienceSection mdxType="ExperienceSection" />
    <Box sx={{
      textAlign: 'center'
    }} mdxType="Box">
      <PortfolioSection mdxType="PortfolioSection" />
      <h1>{`Skill`}</h1>
    </Box>
    <Grid gap={2} columns={['1fr', '1fr', '1fr 1fr']} bg="primary" mdxType="Grid">
      <Box m={3} mdxType="Box">
  <SkillSection mdxType="SkillSection" />
      </Box>
  <Box m={3} mdxType="Box">
        <LinkedInSkillSection mdxType="LinkedInSkillSection" />
  </Box>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      